import React from 'react';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import {PlusOutlined, MinusOutlined} from '@ant-design/icons';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import constants from '../../constants';

function Faq(props) {
  const [dimension] = useOutlet('dimension');
  const [cats, setCats] = React.useState(null);
  const [selectedCat, setSelectedCat] = React.useState('');
  const [selectedCatItem, setSelectedCatItem] = React.useState('');

  const fetchRecords = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      let resp = await JStorage.fetchAllDocuments('faq', {}, ['order']);
      setCats(resp);
      setSelectedCat(resp[0]?.name);
    } catch (err) {
      console.warn(err);
    } finally {
      AppActions.setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  const isMobile = dimension.rwd === 'mobile';

  return (
    <Wrapper rwd={isMobile} id="rev-FaqPage">
      <div className="top">
        <div className="content">
          <h2>常見問題</h2>
        </div>
      </div>

      <div className="medium">
        <div className="content">
          {cats?.map((cat) => (
            <Button
              selected={cat.name === selectedCat}
              onClick={() => {
                setSelectedCat(cat.name);
              }}>
              {cat.name}
            </Button>
          ))}
        </div>
      </div>

      <div className="bottom">
        <div className="content">
          {cats
            ?.find((cat) => cat.name === selectedCat)
            ?.items?.map((item) => {
              const isSelectCatItem = item.title === selectedCatItem;
              return (
                <Card rwd={isMobile} selected={item.title === selectedCatItem}>
                  <div className="header">
                    <h3>{item.title}</h3>
                    <button
                      onClick={() =>
                        isSelectCatItem
                          ? setSelectedCatItem('')
                          : setSelectedCatItem(item.title)
                      }>
                      {isSelectCatItem ? (
                        <MinusOutlined
                          size={26}
                          style={{color: constants.THEME_COLOR}}
                        />
                      ) : (
                        <PlusOutlined
                          size={26}
                          style={{color: constants.THEME_COLOR}}
                        />
                      )}
                    </button>
                  </div>

                  <div className="content">
                    <div dangerouslySetInnerHTML={{__html: item.content}} />
                  </div>
                </Card>
              );
            })}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  min-height: var(--contentMinHeight);

  & > .top {
    padding: 40px;
    background-color: rgba(195, 195, 195, 0.15);

    & > .content {
      max-width: 560px;
      margin: 0 auto;
      text-align: center;

      & > h2 {
        font-size: 28px;
        color: #313131;
      }
    }
  }

  & > .medium {
    padding: ${(props) => (props.rwd ? '20px' : '40px')};

    & > .content {
      max-width: var(--contentMaxWidth);
      margin: 0 auto;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  & > .bottom {
    padding: ${(props) => (props.rwd ? '20px' : '10px')};

    & > .content {
      max-width: var(--contentMaxWidth);
      width: 100%;
      margin: 0 auto;
    }
  }
`;

const Button = styled.button`
  margin: 10px;
  padding: 10px 15px;
  min-width: 100px;
  border-radius: 15px;
  cursor: pointer;

  border: 1px solid
    ${(props) => (props.selected ? 'var(--primaryColor)' : '#e8e8e8')};
  background-color: ${(props) =>
    props.selected ? 'var(--primaryColor)' : '#ffffff'};
  color: ${(props) => (props.selected ? '#ffffff' : '#727272')};

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  }

  &:active {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  }
`;

const Card = styled.div`
  margin-bottom: 25px;
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  padding: ${(props) => (props.rwd ? '23px 15px' : '23px 50px')};

  & > .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: ${(props) => (props.selected ? '23px' : '0px')};
    border-bottom: ${(props) => (props.selected ? '1px solid #CBCBCB' : '0px')};

    & > h3 {
      color: #5dacb7;
    }

    & > button {
      cursor: pointer;
      border: 0px;
      background-color: #ffffff;
    }
  }

  & > .content {
    transition: all 100ms linear 10ms;

    ${(props) =>
      props.selected
        ? `
            height: auto;
            overflow: auto;
            opacity: 1;

            padding-top: 23px;
            line-height: 2.25;
            white-space: pre-line;
          `
        : `
            height: 0px;
            overflow: hidden;
            opacity: 0;
          `}
  }
`;

export default withPageEntry(Faq);
